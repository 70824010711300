var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-bg-full mg-b-4",class:_vm.haveEdit == true ? 'have-button' : ''},[_c('span',[_vm._v("Certificate Schema (ข้อมูลที่ขอการรับรอง)")]),(_vm.haveEdit == true)?_c('el-button',{staticClass:"button-radius font-14",attrs:{"type":"white"},on:{"click":function($event){return _vm.$emit('editStep', 2)}}},[_c('i',{staticClass:"far fa-edit"}),_vm._v("แก้ไข")]):_vm._e()],1),_c('el-card',{staticClass:"custom-card requisition-condition blue",class:_vm.form.checkboxFood ? 'active' : '',attrs:{"shadow":"never"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[(_vm.type == 'add')?_c('el-checkbox',{on:{"change":function($event){return _vm.$emit(
            'checkError',
            _vm.form.checkboxFood == false && _vm.form.checkboxISO == false
              ? true
              : false,
            'foodSafety'
          )}},model:{value:(_vm.form.checkboxFood),callback:function ($$v) {_vm.$set(_vm.form, "checkboxFood", $$v)},expression:"form.checkboxFood"}}):_vm._e(),_c('span',[_vm._v("ตรวจ Food Safety")])],1),(_vm.form.checkboxFood)?_c('div',[_vm._l((_vm.form.conditionsFood),function(conditions,indexC){return _c('el-card',{key:indexC,staticClass:"card-inside",attrs:{"shadow":"never"}},[_c('el-row',{staticClass:"flex-wrap",attrs:{"gutter":15,"type":"flex"}},[_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"Standard (มาตรฐาน)","prop":'conditionsFood.' + indexC + '.standard',"rules":{
                required: true,
                message: 'กรุณาเลือก Standard',
                trigger: ['change'],
              }}},[(_vm.type == 'view')?_c('span',[_vm._v(" "+_vm._s(_vm.mapDataOption( conditions.standard, _vm.optionsStandardFood, "standard" ))+" ")]):_c('el-select',{attrs:{"filterable":"","clearable":"","placeholder":"Standard","no-match-text":"ไม่พบข้อมูลที่ค้นหา"},model:{value:(conditions.standard),callback:function ($$v) {_vm.$set(conditions, "standard", $$v)},expression:"conditions.standard"}},_vm._l((_vm.optionsStandardFood),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.standardName,"value":item.standardNum}})}),1)],1)],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"Accredit By","prop":'conditionsFood.' + indexC + '.accrecditBy',"rules":{
                required: true,
                message: 'กรุณาเลือก Accredit By',
                trigger: ['change'],
              }}},[(_vm.type == 'view')?_c('span',[_vm._v(" "+_vm._s(_vm.mapDataOption(conditions.accrecditBy, _vm.optionAccrecditFood))+" ")]):_c('el-select',{attrs:{"filterable":"","clearable":"","placeholder":"Accredit By","no-match-text":"ไม่พบข้อมูลที่ค้นหา"},model:{value:(conditions.accrecditBy),callback:function ($$v) {_vm.$set(conditions, "accrecditBy", $$v)},expression:"conditions.accrecditBy"}},_vm._l((_vm.optionAccrecditFood),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"Scope (ขอบข่ายขอการรับรอง)","prop":'conditionsFood.' + indexC + '.scoped',"rules":{
                required: true,
                message: 'กรุณากรอก Scope',
                trigger: 'blur',
              }}},[(_vm.type == 'view')?_c('span',[_vm._v(" "+_vm._s(conditions.scoped)+" ")]):_c('el-input',{attrs:{"placeholder":"Scope (ขอบข่ายขอการรับรอง)"},model:{value:(conditions.scoped),callback:function ($$v) {_vm.$set(conditions, "scoped", $$v)},expression:"conditions.scoped"}})],1)],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"CAT No.","prop":'conditionsFood.' + indexC + '.catNo',"rules":{
                type: 'array',
                required: true,
                validator: conditions.catNo.length == 0 ? _vm.validateCat : '',
                trigger: ['change'],
              }}},[(_vm.type == 'view')?_c('div',_vm._l((conditions.catNo),function(tag,indexS){return _c('span',{key:indexS},[_vm._v(" "+_vm._s(tag)+" ")])}),0):_c('el-input',{attrs:{"placeholder":"CAT No."},nativeOn:{"keypress":function($event){return _vm.isNumber($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addCatNo(indexC, 'foodSafety', conditions.valCatFoodSafety)}},model:{value:(conditions.valCatFoodSafety),callback:function ($$v) {_vm.$set(conditions, "valCatFoodSafety", $$v)},expression:"conditions.valCatFoodSafety"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addCatNo(
                      indexC,
                      'foodSafety',
                      conditions.valCatFoodSafety
                    )}},slot:"append"})],1)],1),(_vm.type != 'view')?_c('div',_vm._l((conditions.catNo),function(tag,indexS){return _c('el-tag',{key:indexS,attrs:{"closable":"","disable-transitions":false},on:{"close":function($event){return _vm.delCatNo(indexC, indexS, 'foodSafety')}}},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_vm._e()],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"รอบการตรวจติดตาม","prop":'conditionsFood.' + indexC + '.auditCycle',"rules":{
                required: true,
                message: 'กรุณาเลือก รอบการตรวจติดตาม',
                trigger: 'change',
              }}},[(_vm.type == 'view')?_c('span',[(conditions.auditCycle == 6)?_c('span',[_vm._v(" 6 เดือน ")]):(conditions.auditCycle == 12)?_c('span',[_vm._v(" 12 เดือน ")]):_vm._e()]):_c('el-radio-group',{model:{value:(conditions.auditCycle),callback:function ($$v) {_vm.$set(conditions, "auditCycle", $$v)},expression:"conditions.auditCycle"}},[_c('el-radio',{attrs:{"label":6}},[_vm._v("6 เดือน")]),_c('el-radio',{attrs:{"label":12}},[_vm._v("12 เดือน")])],1)],1)],1)],1),(indexC > 0 && _vm.type != 'view')?_c('div',{staticClass:"del-condition",on:{"click":function($event){return _vm.$emit('delCondition', 'foodSafety', indexC)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()],1)}),(_vm.type != 'view')?_c('el-button',{staticClass:"button-radius font-14 w-100 mg-t-4",attrs:{"type":"blue-dark"},on:{"click":function($event){return _vm.$emit('addCondition', 'foodSafety')}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" เพิ่มการตรวจ Food Safety")]):_vm._e()],2):_vm._e()]),_c('el-card',{staticClass:"custom-card requisition-condition blue-light",class:_vm.form.checkboxISO ? 'active' : '',attrs:{"shadow":"never"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[(_vm.type == 'add')?_c('el-checkbox',{on:{"change":function($event){return _vm.$emit(
            'checkError',
            _vm.form.checkboxFood == false && _vm.form.checkboxISO == false
              ? true
              : false,
            'iso'
          )}},model:{value:(_vm.form.checkboxISO),callback:function ($$v) {_vm.$set(_vm.form, "checkboxISO", $$v)},expression:"form.checkboxISO"}}):_vm._e(),_c('span',[_vm._v("ตรวจ ISO")])],1),(_vm.form.checkboxISO)?_c('div',[_vm._l((_vm.form.conditionsISO),function(conditions,indexISO){return _c('el-card',{key:indexISO,staticClass:"card-inside",attrs:{"shadow":"never"}},[_c('el-row',{staticClass:"flex-wrap",attrs:{"gutter":15,"type":"flex"}},[_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"Company (บริษัท)","prop":'conditionsISO.' + indexISO + '.company',"rules":{
                required: true,
                message: 'กรุณากรอก Company (บริษัท)',
                trigger: 'blur',
              }}},[(_vm.type == 'view')?_c('span',[_vm._v(" "+_vm._s(_vm.mapDataOption( conditions.company, _vm.optionsCompanyIso, "company" ))+" ")]):_c('el-select',{attrs:{"filterable":"","clearable":"","placeholder":"Company (บริษัท)","no-match-text":"ไม่พบข้อมูลที่ค้นหา"},on:{"change":function($event){conditions.standard = ''}},model:{value:(conditions.company),callback:function ($$v) {_vm.$set(conditions, "company", $$v)},expression:"conditions.company"}},_vm._l((_vm.optionsCompanyIso),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.companyName,"value":item.companyNum}})}),1)],1)],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"Standard (มาตรฐาน)","prop":'conditionsISO.' + indexISO + '.standard',"rules":{
                required: true,
                message: 'กรุณาเลือก Standard',
                trigger: ['change'],
              }}},[(_vm.type == 'view')?_c('span',[_vm._v(" "+_vm._s(_vm.mapDataOption( conditions.standard, conditions.company == 1 ? _vm.optionsStandardIsoOne : _vm.optionsStandardIsoTwo, "standard" ))+" "+_vm._s(conditions.standard == 99 ? `(${conditions.other})` : "")+" ")]):_c('el-row',{staticClass:"flex-wrap",attrs:{"gutter":15,"type":"flex"}},[_c('el-col',{attrs:{"span":24,"md":conditions.standard == 99 ? 12 : 24}},[_c('el-select',{attrs:{"filterable":"","clearable":"","placeholder":"Standard","no-match-text":"ไม่พบข้อมูลที่ค้นหา","disabled":!conditions.company ? true : false},model:{value:(conditions.standard),callback:function ($$v) {_vm.$set(conditions, "standard", $$v)},expression:"conditions.standard"}},_vm._l((conditions.company == 1
                        ? _vm.optionsStandardIsoOne
                        : _vm.optionsStandardIsoTwo),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.standardName,"value":item.standardNum}})}),1)],1),(conditions.standard == 99)?_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{staticClass:"mg-b-less",attrs:{"prop":'conditionsISO.' + indexISO + '.other',"rules":{
                      required: true,
                      message: 'กรุณากรอก',
                      trigger: ['change'],
                    }}},[_c('el-input',{model:{value:(conditions.other),callback:function ($$v) {_vm.$set(conditions, "other", $$v)},expression:"conditions.other"}})],1)],1):_vm._e()],1)],1)],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"Accredit By","prop":'conditionsISO.' + indexISO + '.accrecditBy',"rules":{
                required: true,
                message: 'กรุณาเลือก Accredit By',
                trigger: ['change'],
              }}},[(_vm.type == 'view')?_c('span',[_vm._v(" "+_vm._s(_vm.mapDataOption(conditions.accrecditBy, _vm.optionAccrecditISO))+" ")]):_c('el-select',{attrs:{"filterable":"","clearable":"","placeholder":"Accredit By","no-match-text":"ไม่พบข้อมูลที่ค้นหา"},model:{value:(conditions.accrecditBy),callback:function ($$v) {_vm.$set(conditions, "accrecditBy", $$v)},expression:"conditions.accrecditBy"}},_vm._l((_vm.optionAccrecditISO),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"Scope (ขอบข่ายขอการรับรอง)","prop":'conditionsISO.' + indexISO + '.scoped',"rules":{
                required: true,
                message: 'กรุณากรอก Scope',
                trigger: 'blur',
              }}},[(_vm.type == 'view')?_c('span',[_vm._v(" "+_vm._s(conditions.scoped)+" ")]):_c('el-input',{attrs:{"placeholder":"Scope (ขอบข่ายขอการรับรอง)"},model:{value:(conditions.scoped),callback:function ($$v) {_vm.$set(conditions, "scoped", $$v)},expression:"conditions.scoped"}})],1)],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"CAT No.","prop":'conditionsISO.' + indexISO + '.catNo',"rules":{
                type: 'array',
                required: true,
                validator: conditions.catNo.length == 0 ? _vm.validateCatISO : '',
                trigger: ['change'],
              }}},[(_vm.type == 'view')?_c('div',_vm._l((conditions.catNo),function(tag,indexS){return _c('span',{key:indexS},[_vm._v(" "+_vm._s(tag)+" ")])}),0):_c('el-input',{attrs:{"placeholder":"CAT No."},nativeOn:{"keypress":function($event){return _vm.isNumber($event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addCatNo(indexISO, 'iso', conditions.valCatISO)}},model:{value:(conditions.valCatISO),callback:function ($$v) {_vm.$set(conditions, "valCatISO", $$v)},expression:"conditions.valCatISO"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-plus"},on:{"click":function($event){return _vm.addCatNo(indexISO, 'iso', conditions.valCatISO)}},slot:"append"})],1)],1),(_vm.type != 'view')?_c('div',_vm._l((conditions.catNo),function(tag,indexS){return _c('el-tag',{key:indexS,attrs:{"closable":"","disable-transitions":false},on:{"close":function($event){return _vm.delCatNo(indexISO, indexS, 'iso')}}},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_vm._e()],1),_c('el-col',{attrs:{"span":24,"md":12}},[_c('el-form-item',{attrs:{"label":"รอบการตรวจติดตาม","prop":'conditionsISO.' + indexISO + '.auditCycle',"rules":{
                required: true,
                message: 'กรุณาเลือก รอบการตรวจติดตาม',
                trigger: 'change',
              }}},[(_vm.type == 'view')?_c('span',[(conditions.auditCycle == 6)?_c('span',[_vm._v(" 6 เดือน ")]):(conditions.auditCycle == 12)?_c('span',[_vm._v(" 12 เดือน ")]):_vm._e()]):_c('el-radio-group',{model:{value:(conditions.auditCycle),callback:function ($$v) {_vm.$set(conditions, "auditCycle", $$v)},expression:"conditions.auditCycle"}},[_c('el-radio',{attrs:{"label":6}},[_vm._v("6 เดือน")]),_c('el-radio',{attrs:{"label":12}},[_vm._v("12 เดือน")])],1)],1)],1)],1),(_vm.form.conditionsISO.length > 1 && _vm.type != 'view')?_c('div',{staticClass:"del-condition",on:{"click":function($event){return _vm.$emit('delCondition', 'iso', indexISO)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()],1)}),(_vm.type != 'view')?_c('el-button',{staticClass:"button-radius font-14 w-100 mg-t-4",attrs:{"type":"blue-light"},on:{"click":function($event){return _vm.$emit('addCondition', 'iso')}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" เพิ่มการตรวจ ISO")]):_vm._e()],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }