<template>
  <div>
    <el-row :gutter="15" type="flex" class="flex-wrap">
      <el-col :span="24" class="mg-b-4">
        <div
          class="text-bg-full"
          :class="haveEdit == true ? 'have-button' : ''"
        >
          <span>Basic Information of Client (ข้อมูลบริษัท)</span>
          <el-button
            v-if="haveEdit == true"
            type="white"
            class="button-radius font-14"
            @click="$emit('editStep', 1)"
          >
            <i class="far fa-edit"></i>แก้ไข</el-button
          >
        </div>
      </el-col>
      <el-col :span="24">
        <el-row :gutter="20" :class="type == 'view' ? 'pd-x-1' : ''">
          <el-col :span="24">
            <el-row :gutter="15" type="flex" class="flex-wrap">
              <el-col :span="24" :md="12">
                <el-form-item
                  prop="companyName"
                  label="Company name (ชื่อบริษัท)"
                  :class="errorCompany == true ? `is-error` : ''"
                >
                  <span v-if="type == 'view'" v-html="form.companyName"> </span>
                  <el-autocomplete
                    v-else
                    v-model="form.companyName"
                    popper-class="custom-autocomplete"
                    placeholder="Company name"
                    :fetch-suggestions="querySearchCompany"
                    @blur="form.companyName = form.companyName.trim()"
                  >
                    <template slot-scope="{ item }">
                      <div class="value"><span v-html="item.label"></span></div>
                    </template>
                  </el-autocomplete>
                  <div class="el-form-item__error" v-if="errorCompany">
                    ชื่อบริษัทนี้ได้ถูกใช้งานแล้ว
                  </div>
                </el-form-item>
                <!-- <el-form-item
                  label="Company name (ชื่อบริษัท)"
                  prop="companyName"
                >
                  <span v-if="type == 'view'">
                    {{ form.companyName }}
                  </span>
                  <el-input
                    v-else
                    v-model="form.companyName"
                    placeholder="Company name"
                    @blur="form.companyName = form.companyName.trim()"
                  ></el-input>
                </el-form-item> -->
              </el-col>
              <el-col :span="24" :md="12">
                <el-form-item label="ID (เลขนิติบุคคล)" prop="juristicId">
                  <span v-if="type == 'view'">
                    {{ form.juristicId ? form.juristicId : "-" }}
                  </span>
                  <el-input
                    v-else
                    v-model="form.juristicId"
                    placeholder="ID"
                    maxlength="13"
                    @keypress.native="isNumber($event)"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item
              prop="address"
              label="Address Of Client (ที่อยู่)"
              :class="errorAddress == true ? `is-error` : ''"
            >
              <span v-if="type == 'view'" v-html="returnAddress(form.address)">
              </span>
              <el-autocomplete
                v-else
                v-model="form.address"
                popper-class="custom-autocomplete"
                placeholder="Address Of Client"
                :fetch-suggestions="querySearch"
                @blur="form.address = form.address.trim()"
              >
                <template slot-scope="{ item }">
                  <div class="value"><span v-html="item.label"></span></div>
                </template>
              </el-autocomplete>
              <div class="el-form-item__error" v-if="errorAddress">
                ที่อยู่นี้ได้ถูกใช้งานแล้ว
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="12">
            <el-form-item
              label="Contact person name (ชื่อผู้ติดต่อ) "
              prop="contactPerson"
            >
              <span v-if="type == 'view'">
                {{ form.contactPerson }}
              </span>
              <el-input
                v-else
                v-model="form.contactPerson"
                placeholder="Contact person name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="12">
            <el-form-item label="Phone number (เบอร์โทร)" prop="phone">
              <span v-if="type == 'view'">
                {{ form.phone }}
              </span>
              <el-input
                v-else
                v-model="form.phone"
                @keypress.native="isNumber($event)"
                placeholder="Phone number"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="12">
            <el-form-item
              label="E-mail (อีเมล)"
              prop="email"
              :rules="{
                type: 'email',
                message: 'กรุณากรอกอีเมลให้ถูกต้อง',
                trigger: ['change'],
              }"
            >
              <span v-if="type == 'view'">
                {{ form.email ? form.email : "-" }}
              </span>
              <el-input
                v-else
                v-model="form.email"
                placeholder="E-mail"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
    haveEdit: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Array,
      default: () => [],
    },

    companyAll: {
      type: Array,
      default: () => [],
    },
    errorAddress: {
      type: Boolean,
      default: false,
    },
    errorCompany: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    querySearch(query, cb) {
      var address = this.address;
      var results = query ? address.filter(this.createFilter(query)) : address;
      cb(results);
    },
    querySearchCompany(query, cb) {
      var companyAll = this.companyAll;
      var results = query
        ? companyAll.filter(this.createFilter(query))
        : companyAll;
      cb(results);
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
  },
};
</script>
