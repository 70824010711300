var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"ruleForm",attrs:{"label-position":"top","model":_vm.form,"status-icon":"","rules":_vm.rules}},[_c('Step1',{attrs:{"form":_vm.form,"type":_vm.type,"haveEdit":_vm.haveEdit},on:{"editStep":(page) => {
          _vm.$emit('editStep', page);
        }}}),_c('Step2',{attrs:{"form":_vm.form,"type":_vm.type,"haveEdit":_vm.haveEdit},on:{"editStep":(page) => {
          _vm.$emit('editStep', page);
        }}}),_c('Step3',{attrs:{"form":_vm.form,"type":_vm.type,"haveEdit":_vm.haveEdit},on:{"editStep":(page) => {
          _vm.$emit('editStep', page);
        }}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }