<template>
  <div>
    <div
      class="text-bg-full mg-y-4"
      :class="haveEdit == true ? 'have-button mg-t-4' : ''"
    >
      <span>Additional Documents (เอกสารเพิ่มเติม)</span>
      <el-button
        v-if="haveEdit == true"
        type="white"
        class="button-radius font-14"
        @click="$emit('editStep', 3)"
      >
        <i class="far fa-edit"></i>แก้ไข</el-button
      >
    </div>
    <el-form-item
      label="Upload File (อัปโหลดไฟล์ครั้งละไม่เกิน 25 MB)"
      prop="files"
      :class="type == 'view' ? 'view-only' : ''"
    >
      <el-upload
        drag
        multiple
        action="#"
        :auto-upload="false"
        :on-change="(file, fileList) => uploadDocuments(file, fileList)"
        :on-remove="handleRemoveDocuments"
        list-type="picture"
        :file-list="form.fileList"
        :on-preview="(file) => perviewFile(file)"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
      </el-upload>
    </el-form-item>
    <el-form-item label="Note (เพิ่มเติม)">
      <span v-if="type == 'view'">
        {{ form.note ? form.note : "-" }}
      </span>
      <el-input v-else v-model="form.note" placeholder="Note"></el-input>
    </el-form-item>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      imgDoc: (state) => state.imgLogoDoc,
    }),
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
    haveEdit: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    form() {
      this.checkFileList();
    },
  },
  mounted() {
    this.checkFileList();
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  methods: {
    checkFileList() {
      this.form.fileList = [];
      if (this.form.files.length > 0) {
        this.form.fileList = this.form.files.reduce((result, row) => {
          var obj = {};
          if (row.file == undefined && row.file == null) {
            let setUrl =
              this.imageUrl + "certification/" + this.form._id + "/" + row;
            let typeThis = row.split(".");
            if (typeThis[typeThis.length - 1] == "pdf") {
              setUrl = this.imgDoc.pdf;
            } else if (
              typeThis[typeThis.length - 1] == "docx" ||
              typeThis[typeThis.length - 1] == "doc"
            ) {
              setUrl = this.imgDoc.docx;
            } else if (
              typeThis[typeThis.length - 1] == "xlsx" ||
              typeThis[typeThis.length - 1] == "xls" ||
              typeThis[typeThis.length - 1] == "csv"
            ) {
              setUrl = this.imgDoc.xlsx;
            } else if (
              typeThis[typeThis.length - 1] == "ppt" ||
              typeThis[typeThis.length - 1] == "pptx"
            ) {
              setUrl = this.imgDoc.ppt;
            } else if (typeThis[typeThis.length - 1] == "zip") {
              setUrl = this.imgDoc.zip;
            }
            obj = {
              name: row,
              url: setUrl,
              _id: this.form._id,
            };
          } else {
            let setUrl = URL.createObjectURL(row.file.raw);
            let typeThis = row.file.name.split(".");
            if (typeThis[typeThis.length - 1] == "pdf") {
              setUrl = this.imgDoc.pdf;
            } else if (
              typeThis[typeThis.length - 1] == "docx" ||
              typeThis[typeThis.length - 1] == "doc"
            ) {
              setUrl = this.imgDoc.docx;
            } else if (
              typeThis[typeThis.length - 1] == "xlsx" ||
              typeThis[typeThis.length - 1] == "xls" ||
              typeThis[typeThis.length - 1] == "csv"
            ) {
              setUrl = this.imgDoc.xlsx;
            } else if (
              typeThis[typeThis.length - 1] == "ppt" ||
              typeThis[typeThis.length - 1] == "pptx"
            ) {
              setUrl = this.imgDoc.ppt;
            } else if (typeThis[typeThis.length - 1] == "zip") {
              setUrl = this.imgDoc.zip;
            }
            obj = {
              name: row.file.name,
              url: setUrl,
            };
          }

          result.push(obj);
          return result;
        }, []);
      }
    },
    uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");
      let type = file.raw.type.split("/");
      let indexOfFile = typeThis.length > 1 ? typeThis.length - 1 : 1;
      if (type[indexOfFile] != "json") {
        if (file.size < 25000000) {
          if (typeThis[indexOfFile] == "pdf") {
            file.url = this.imgDoc.pdf;
          } else if (
            typeThis[indexOfFile] == "docx" ||
            typeThis[indexOfFile] == "doc"
          ) {
            file.url = this.imgDoc.docx;
          } else if (
            typeThis[indexOfFile] == "xlsx" ||
            typeThis[indexOfFile] == "xls" ||
            typeThis[indexOfFile] == "csv"
          ) {
            file.url = this.imgDoc.xlsx;
          } else if (
            typeThis[indexOfFile] == "ppt" ||
            typeThis[indexOfFile] == "pptx"
          ) {
            file.url = this.imgDoc.ppt;
          } else if (typeThis[indexOfFile] == "zip") {
            file.url = this.imgDoc.zip;
          }
          let obj = {
            name: file.name,
            file: file,
          };
          this.form.files.push(obj);
          this.form.fileList.push({
            name: file.raw.name,
            url: file.url,
            // uid: file.uid,
          });
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25 MB",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        this.form.fileList = JSON.parse(JSON.stringify(this.form.fileList));
        this.$message({
          message: "ไม่สามารถอัพโหลดไฟล์นามสกุล JSON ได้",
          type: "error",
          duration: 4000,
        });
      }
    },

    handleRemoveDocuments(file) {
      if (file._id != undefined) {
        let fileName =
          this.imageUrl + "certification/" + this.form._id + "/" + file.name;

        this.form.delFiles.push(file.name);
      }

      let index = this.form.fileList.findIndex((f) => f.uid == file.uid);
      this.form.files.splice(index, 1);
      this.form.fileList.splice(index, 1);
    },
    perviewFile(file) {
      if (file.raw == undefined && file.raw == null) {
        let id = "";
        id = this.haveEdit == false ? this.form._id : this.$route.params.id;
        window.open(this.imageUrl + "certification/" + id + "/" + file.name);
      } else {
        window.open(URL.createObjectURL(file.raw));
      }
    },
  },
};
</script>
