<template>
  <div>
    <el-form
      label-position="top"
      :model="form"
      ref="ruleForm"
      status-icon
      :rules="rules"
    >
      <Step1
        :form="form"
        :type="type"
        :haveEdit="haveEdit"
        @editStep="
          (page) => {
            $emit('editStep', page);
          }
        "
      />
      <Step2
        :form="form"
        :type="type"
        :haveEdit="haveEdit"
        @editStep="
          (page) => {
            $emit('editStep', page);
          }
        "
      />
      <Step3
        :form="form"
        :type="type"
        :haveEdit="haveEdit"
        @editStep="
          (page) => {
            $emit('editStep', page);
          }
        "
      />
    </el-form>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Step1 from "@/components/requisition/add/Step1";
import Step2 from "@/components/requisition/add/Step2";
import Step3 from "@/components/requisition/add/Step3";

export default {
  components: {
    Step1,
    Step2,
    Step3,
  },
  computed: {
    ...mapState({
      imgDoc: (state) => state.imgLogoDoc,
    }),
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String(),
      default: "",
    },
    haveEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  mounted() {},
  methods: {},
};
</script>
